import { configureStore } from "@reduxjs/toolkit";
import jobsSlice from "./jobsSlice";
import meetingsSlice from "./meetingsSlice";
import templatesSlice from "./templatesSlice";
import reportsSlice from "./reportsSlice";
import appSlice from "./appSlice";

const store = configureStore({
  reducer: {
    app: appSlice,
    jobs: jobsSlice,
    meetings: meetingsSlice,
    templates: templatesSlice,
    reports: reportsSlice,
  },
});

export default store;
