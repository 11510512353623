import React, { useEffect, useState } from "react";
import Topbar from "../Sidebars/Topbar";
import { LockClosedIcon, PencilSquareIcon } from "@heroicons/react/24/solid";
import { HeartIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useSelector } from "react-redux";
import { supabase } from "../../supabase";

function Templates() {
  const { data: templates } = useSelector((state) => state.templates);
  const [templateEditable, setTemplateEditable] = useState({});

  useEffect(() => {
    if (
      Object.values(templateEditable).length === 0 &&
      Object.values(templates).length > 0
    ) {
      setTemplateEditable(Object.values(templates)[0]);
    }
  }, [templates]);

  const updateTemplate = async (newData) => {
    const { data, error } = await supabase
      .from("templates")
      .update(newData)
      .eq("id", templateEditable?.id)
      .select();

    if (error) {
      console.log("error", error);
    }
  };

  const deleteTemplate = async (templateId) => {
    const { error } = await supabase
      .from("templates")
      .delete()
      .eq("id", templateId);

    if (error) {
      console.log("error", error);
    }
  };

  const createTemplate = async () => {
    const { data, error } = await supabase.from("templates").insert([
      {
        name: "Untitled",
        prompt: "You are an assistant that summarizes notes for a meeting",
        org_id: 1,
      },
    ]);

    if (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <div className="flex flex-col h-full">
        <Topbar />
        <div className="flex h-[43.9rem] w-full">
          <div className="flex flex-col border border-y-0 border-l-0 border-neutral-200 py-8  w-[20rem] gap-8 overflow-auto relative">
            <div className="flex flex-col gap-0.5 px-4 overflow-auto">
              {/* <div className="text-xs font-medium text-neutral-400">
                My Templates
              </div> */}
              {Object.keys(templates).map((key) => {
                const template = templates[key];

                return (
                  <div
                    className={`flex items-center gap-2 w-full hover:bg-neutral-100 cursor-pointer rounded-lg py-2 px-2 ${
                      Number(key) === Number(templateEditable?.id) &&
                      "bg-neutral-100"
                    }`}
                    onClick={() => setTemplateEditable(template)}
                    key={key}
                  >
                    <div className="bg-[#F3E8FF] text-xl p-2 rounded-lg w-10 h-10 flex items-center justify-center">
                      👥
                    </div>
                    <div className="flex flex-col">
                      <div>{template?.name}</div>
                      <div className="text-xs text-neutral-500 font-medium">
                        Juno
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div
              className="absolute bottom-0 bg-white border border-x-0 border-b-0 border-neutral-200 w-full px-4 py-2"
              onClick={createTemplate}
            >
              + Create template
            </div>
          </div>
          <div className="flex flex-col gap-6 pt-8 w-full">
            <div className="flex justify-between w-full px-8">
              <div className="flex gap-6 items-center">
                <div className="rounded-lg w-10 h-10 bg-[#F3E8FF] text-xl p-4 flex justify-center items-center border border-neutral-200">
                  👥
                </div>
                <input
                  className="text-2xl font-semibold outline-none px-2 py-1"
                  value={templateEditable?.name}
                  onChange={(e) => {
                    setTemplateEditable({
                      ...templateEditable,
                      name: e.target.value,
                    });
                  }}
                  onBlur={() =>
                    updateTemplate({ name: templateEditable?.name })
                  }
                />
              </div>
              <div className="flex gap-6 items-center">
                <PencilSquareIcon className="w-5 h-5 cursor-pointer text-neutral-600" />
                <HeartIcon className="w-5 h-5 cursor-pointer" />
                <TrashIcon
                  className="w-5 h-5 cursor-pointer"
                  onClick={() => {
                    if (!templateEditable?.is_primary) {
                      deleteTemplate(templateEditable?.id);
                    } else {
                      alert("Cannot delete primary template");
                    }
                  }}
                />
              </div>
            </div>
            <div className="flex gap-4 px-8">
              <div className="flex gap-1 items-center">
                <div className="w-6 h-6 rounded-full bg-blue-500 text-blue-200 text-sm flex justify-center items-center font-bold ">
                  J
                </div>
                <div className="text-sm font-medium">Juno</div>
              </div>
              <div className="flex items-center gap-1">
                <LockClosedIcon className="text-slate-500 w-3 h-3" />
                <div className="text-xs text-slate-500 font-medium">
                  View Only
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-8 py-8 px-8">
              <div className="flex flex-col gap-2">
                <div className="text-sm font-semibold text-slate-500">
                  Meeting Context
                </div>
                <div>
                  Current updates, feedback for them, feedback received, and
                  upcoming milestones & strategies
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <div className="text-sm font-semibold text-slate-500">
                  Sections
                </div>
                <div className="flex flex-col gap-4">
                  <div className="p-4 rounded-xl border border-neutral-200">
                    <div>Raw Template</div>
                    <textarea
                      className="text-slate-500 text-sm font-medium w-full min-h-[20rem] outline-none"
                      value={templateEditable?.prompt}
                      onChange={(e) => {
                        setTemplateEditable({
                          ...templateEditable,
                          prompt: e.target.value,
                        });
                      }}
                      onBlur={() =>
                        updateTemplate({ prompt: templateEditable?.prompt })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Templates;
