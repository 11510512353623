import Dragger from "antd/es/upload/Dragger";
import React, { useEffect, useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { Dropdown, message, Upload } from "antd";
import axios from "axios";
import { IoMdAdd } from "react-icons/io";
import { FaChevronDown } from "react-icons/fa";
import { IoChevronDown } from "react-icons/io5";
import { supabase } from "../../supabase";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { createMeeting } from "../../redux/meetingsSlice";

function NewMeeting({ setNewMeetingVisible, jobStatus }) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [meetingTitle, setMeetingTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [defaultTemplate, setDefaultTemplate] = useState(null);
  const [newTemplateVisible, setNewTemplateVisible] = useState(false);
  // const [templates, setTemplates] = useState([]);
  const [defaultDropdown, setDefaultDropdown] = useState([]);
  const [newTemplatePrompt, setNewTemplatePrompt] = useState("");
  const [newTemplateName, setNewTemplateName] = useState("");
  const [step, setStep] = useState(0);
  const [providedTranscript, setProvidedTranscript] = useState(null);
  const [providedKeywords, setProvidedKeywords] = useState(null);
  const { Dragger } = Upload;

  const { data: templates = {} } = useSelector(
    (state) => state.templates || {}
  );

  const dispatch = useDispatch();

  const props = {
    name: "file",
    multiple: false,
    beforeUpload: (file) => {
      console.log("Selected File:", file); // Log the selected file details

      const reader = new FileReader();

      reader.onload = (e) => {
        const base64String = e.target.result;
        console.log("Base64 String:", base64String); // Log the base64 string

        if (base64String) {
          setSelectedFile(base64String);
          message.success(`${file.name} file uploaded successfully.`);
        } else {
          message.error(`${file.name} file upload failed.`);
        }
      };

      reader.onerror = (e) => {
        console.error("FileReader error:", e);
        message.error(`${file.name} file upload failed.`);
      };

      reader.readAsDataURL(file); // Trigger reading the file

      return false; // Prevent auto upload
    },
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  const handleMeetingCreation = async () => {
    try {
      console.log("new meeting initiated");
      dispatch(
        createMeeting({
          meetingTitle,
          selectedFile,
          defaultTemplate,
          newTemplateName,
          newTemplatePrompt,
          providedKeywords,
          providedTranscript,
        })
      );

      setNewMeetingVisible(false);
    } catch (error) {
      console.error("error creating new meeting", error);
    }
  };

  useEffect(() => {
    if (Object.keys(templates).length > 0) {
      setDefaultTemplate(Object.keys(templates)[0]);
    }
  }, [templates]);

  useEffect(() => {
    if (Object.keys(templates).length > 0) {
      const updatedDropdown = Object.values(templates).map((template) => {
        return {
          key: template.id,
          label: (
            <div
              onClick={() => {
                setNewTemplateVisible(false);
                setDefaultTemplate(template.id);
              }}
            >
              {template.name}
            </div>
          ),
        };
      });
      // Adding the default item to the end of the dropdown
      updatedDropdown.push({
        key: "100",
        label: (
          <div
            onClick={() => {
              setDefaultTemplate("New");
              setNewTemplateVisible(true);
            }}
          >
            Add new template
          </div>
        ),
      });
      setDefaultDropdown(updatedDropdown);
    }
  }, [templates]);

  return (
    <div className="h-[45rem] flex justify-center overflow-auto">
      <div className="mt-[8rem] flex flex-col w-[35rem] gap-8 ">
        <div className="text-3xl font-medium">
          {/* {step === 0 && "Add a new meeting"}
          {step === 1 && "Add additional context"} */}
          Add a new meeting
        </div>
        {/* {step === 0 && (
          <> */}
        <div className="h-[16rem]">
          <Dragger {...props} className="w-full h-full">
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p className="ant-upload-text">
              Click or drag file to this area to upload
            </p>
          </Dragger>
        </div>
        <div className="bg-neutral-200 p-[0.5px]" />
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <div className="text-sm font-semibold">
              What do you want to call this meeting?
            </div>
            <input
              type="text"
              className="border border-neutral-300 px-4 py-2 rounded-lg"
              placeholder="Name your meeting"
              value={meetingTitle}
              onChange={(e) => setMeetingTitle(e.target.value)}
            />
          </div>
        </div>
        {/* </>
        )} */}
        {/* {step === 1 && (
          <>
            <textarea
              className="border border-neutral-300 px-4 py-2 rounded-xl h-[16rem] resize-none"
              value={providedTranscript}
              onChange={(e) => setProvidedTranscript(e.target.value)}
            ></textarea>
            <div className="bg-neutral-200 p-[0.5px]" />
            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2">
                <div className="text-sm font-semibold">
                  Add keywords? (separate by comma , )
                </div>
                <input
                  type="text"
                  className="border border-neutral-300 px-4 py-2 rounded-lg"
                  placeholder="Jack, Jill, Hill"
                  value={providedKeywords}
                  onChange={(e) => setProvidedKeywords(e.target.value)}
                />
              </div>
            </div>
          </>
        )} */}
        <div className="flex justify-end gap-4 text-xs py-10">
          {/* {step === 0 && ( */}
          <div
            className="px-6 py-2 bg-neutral-50 hover:bg-neutral-100 font-semibold rounded-lg cursor-pointer"
            onClick={() => setNewMeetingVisible(false)}
          >
            Cancel
          </div>
          {/* )} */}
          {/* {step === 1 && (
            <div
              className="px-6 py-2 bg-neutral-50 hover:bg-neutral-100 font-semibold rounded-lg cursor-pointer"
              onClick={() => setStep(0)}
            >
              Back
            </div>
          )} */}
          {/* {step === 0 && ( */}
          <div
            className="px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-lg cursor-pointer"
            // onClick={() => setStep(1)}
            onClick={handleMeetingCreation}
          >
            {loading ? "Loading" : "Add Meeting"}
          </div>
          {/* )} */}
          {/* {step === 1 && (
            <div
              className="px-6 py-2 bg-blue-500 hover:bg-blue-600 text-white font-semibold rounded-lg cursor-pointer"
              onClick={handleMeetingCreation}
            >
              {loading ? "Loading" : "Add Meeting"}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default NewMeeting;
