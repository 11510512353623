import {
  ChatBubbleLeftEllipsisIcon,
  ClipboardDocumentCheckIcon,
  FolderIcon,
  InboxIcon,
  UserGroupIcon,
  WrenchScrewdriverIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  EllipsisHorizontalCircleIcon,
} from "@heroicons/react/24/outline";
import { Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { IoDocumentsOutline } from "react-icons/io5";
import { MdFavoriteBorder, MdOutlineVideoLibrary } from "react-icons/md";
import { TbTemplate } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { set } from "zod";
import { setActiveOrgId } from "../../redux/appSlice";

function LeftSidebar() {
  const path = window.location.pathname;
  const [expandedTabs, setExpandedTabs] = useState([]);
  const [spaceSwitcher, setSpaceSwitcher] = useState([]);
  const { orgs = {}, activeOrgId = null } = useSelector(
    (state) => state.app || {}
  );
  const activeOrg = useSelector((state) => state.app.orgs[activeOrgId]);

  const dispatch = useDispatch();

  const dynamicSidebar = {
    Sales: ["safe2care", "Project Inspire", "She Reads Truth", "Hoblo"],
    Marketing: ["safe2care", "Project Inspire", "She Reads Truth"],
    Operations: ["safe2care", "Project Inspire"],
    Legal: ["safe2care"],
  };

  const privateDynamicSidebar = {
    People: ["Brian", "John", "Jane"],
    Twitter: ["Finney", "Clay Founder", "Elon Musk"],
    Ellie: ["Vegan Tech Nomad"],
  };

  const exampleSpaceColors = ["#0D9888", "#FF6347", "#FF69B4", "#D93D42"];
  const examplePrivateSpaceColors = ["#FF6347", "#FF69B4", "#AA6AE2"];

  useEffect(() => {
    if (orgs && Object.keys(orgs).length > 0) {
      const orgDropdown = Object.keys(orgs).map((org) => {
        return {
          key: org,
          label: (
            <div
              className="flex items-center gap-2"
              onClick={() => {
                dispatch(setActiveOrgId(org));
              }}
            >
              <img
                src={orgs[org]?.logo}
                alt="org logo"
                className="w-5 h-5 rounded-md"
              />
              <div>{orgs[org].name}</div>
            </div>
          ),
        };
      });

      setSpaceSwitcher(orgDropdown);
    }
  }, [orgs, dispatch]);

  return (
    <div className="min-h-screen border border-y-0 border-l-0 border-neutral-200 flex flex-col  w-[14rem]">
      <Dropdown menu={{ items: spaceSwitcher }} trigger={["click"]}>
        <div className="flex gap-2 items-center border border-neutral-200 border-x-0 border-t-0 p-4 h-[3.56rem] cursor-pointer">
          <img
            src={activeOrg?.logo || require("../../assets/aloaLogo.png")}
            alt="aloa logo"
            className="rounded-md h-5 w-5"
          />
          <div className="font-semibold">
            {activeOrg ? activeOrg?.name : "ERR"}
          </div>
          <ChevronDownIcon className="w-3 h-3" />
        </div>
      </Dropdown>
      <div className="flex flex-col gap-8 px-4 py-6 overflow-auto h-[45rem]">
        <div className="flex flex-col">
          <div className="flex flex-col pl-1">
            <SidebarTab
              title={"My Library"}
              icon={
                <MdOutlineVideoLibrary className="w-5 h-5 text-neutral-600 group-hover:text-neutral-600" />
              }
              path={path}
              targetPath="/"
            />
            <SidebarTab
              title={"Inbox"}
              icon={
                <InboxIcon className="w-5 h-5 text-neutral-600 group-hover:text-neutral-600" />
              }
              path={path}
              targetPath="/"
              notificationCount={3}
            />
            <SidebarTab
              title={"More"}
              icon={
                <EllipsisHorizontalCircleIcon className="w-5 h-5 text-neutral-600 group-hover:text-neutral-600" />
              }
              path={path}
              targetPath="/"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="font-medium text-neutral-400 text-xs">Resources</div>
          <div className="flex flex-col pl-1">
            <SidebarTab
              title={"Meetings"}
              icon={
                <ChatBubbleLeftEllipsisIcon className="w-5 h-5 text-neutral-600 group-hover:text-neutral-600" />
              }
              path={path}
              targetPath="/meetings"
            />
            <SidebarTab
              title={"Clients"}
              icon={
                <UserGroupIcon className="w-5 h-5 text-neutral-600 group-hover:text-neutral-600" />
              }
              path={path}
              targetPath="/clients"
            />
            <SidebarTab
              title={"Reports"}
              icon={
                <ClipboardDocumentCheckIcon className="w-5 h-5 text-neutral-600 group-hover:text-neutral-600" />
              }
              path={path}
              targetPath="/reports"
            />
            <SidebarTab
              title={"Templates"}
              icon={
                <WrenchScrewdriverIcon className="w-5 h-5 text-neutral-600 group-hover:text-neutral-600" />
              }
              path={path}
              targetPath="/templates"
            />
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="font-medium text-neutral-400 text-xs">Spaces</div>
          <div className="flex flex-col pl-1">
            {Object.keys(dynamicSidebar).map((key, index) => {
              const space = dynamicSidebar[key];
              return (
                <>
                  <SidebarTab
                    title={key}
                    path={path}
                    targetPath="#"
                    isSpace={true}
                    setExpandedTabs={setExpandedTabs}
                    expandedTabs={expandedTabs}
                    spaceColor={exampleSpaceColors[index]}
                  />
                  {expandedTabs.includes(key) &&
                    space.map((client) => (
                      <div className="pl-4">
                        <SidebarTab
                          title={client}
                          icon={
                            <FolderIcon className="w-5 h-5 text-neutral-600 group-hover:text-neutral-600" />
                          }
                          path={path}
                          targetPath="#"
                          setExpandedTabs={setExpandedTabs}
                        />
                      </div>
                    ))}
                </>
              );
            })}
          </div>
        </div>
        <div className="flex flex-col gap-2">
          <div className="font-medium text-neutral-400 text-xs">Private</div>
          <div className="flex flex-col pl-1">
            {Object.keys(privateDynamicSidebar).map((key, index) => {
              const space = privateDynamicSidebar[key];
              return (
                <>
                  <SidebarTab
                    title={key}
                    path={path}
                    targetPath="#"
                    isSpace={true}
                    setExpandedTabs={setExpandedTabs}
                    expandedTabs={expandedTabs}
                    spaceColor={examplePrivateSpaceColors[index]}
                  />
                  {expandedTabs.includes(key) &&
                    space.map((client) => (
                      <div className="pl-4">
                        <SidebarTab
                          title={client}
                          icon={
                            <FolderIcon className="w-5 h-5 text-neutral-600 group-hover:text-neutral-600" />
                          }
                          path={path}
                          targetPath="#"
                          setExpandedTabs={setExpandedTabs}
                        />
                      </div>
                    ))}
                </>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeftSidebar;

const SidebarTab = ({
  title,
  icon,
  path,
  targetPath,
  notificationCount,
  isSpace,
  setExpandedTabs,
  expandedTabs,
  spaceColor,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`font-medium text-sm flex items-center justify-between gap-3 group cursor-pointer p-2 rounded-md hover:bg-neutral-100 w-full flex-nowrap overflow-hidden text-neutral-600  ${
        path === targetPath && " "
      }`}
      onClick={() => {
        if (isSpace) {
          if (expandedTabs.includes(title)) {
            setExpandedTabs(expandedTabs.filter((tab) => tab !== title));
          } else {
            setExpandedTabs([...expandedTabs, title]);
          }
        } else {
          navigate(targetPath);
        }
      }}
    >
      <div className="flex gap-2 items-center">
        {isSpace ? (
          <div
            className="flex justify-center items-center w-5 h-5 rounded-md text-white text-xs"
            style={{ backgroundColor: isSpace ? spaceColor : "" }}
          >
            {title[0].toUpperCase()}
          </div>
        ) : (
          <div>{icon}</div>
        )}
        <div className="flex-nowrap overflow-hidden w-20 truncate">{title}</div>
      </div>
      {notificationCount && (
        <div className="p-2 rounded-full bg-blue-500 text-white text-xs w-6 h-5 flex justify-center items-center">
          {notificationCount}
        </div>
      )}
    </div>
  );
};
