import React, { useEffect, useState } from "react";
import Topbar from "../Sidebars/Topbar";
import { Link } from "react-router-dom";
import NewMeeting from "../Meeting/NewMeeting";
import { supabase } from "../../supabase";
import {
  ArrowDownTrayIcon,
  ArrowUpTrayIcon,
  ChevronDownIcon,
  LinkIcon,
} from "@heroicons/react/24/outline";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { IoCreateOutline } from "react-icons/io5";
import { TbBoxMultiple } from "react-icons/tb";

function Dashboard() {
  const { data: meetings = {} } = useSelector((state) => state.meetings) || {};
  const [newMeetingVisible, setNewMeetingVisible] = useState(false);

  return (
    <div className="min-h-screen w-full h-full">
      <div className="relative h-full w-full">
        <Topbar setNewMeetingVisible={setNewMeetingVisible} />
        {!newMeetingVisible && (
          <div className="w-full h-full py-8 px-8 flex flex-col gap-8">
            <div className="flex items-center gap-4">
              <div className="flex items-center border border-neutral-200 rounded-lg w-[12rem] h-[4rem] drop-shadow-sm bg-white px-4 py-2 gap-3">
                <div className="bg-[#D1EDFF] rounded-lg w-6 h-6 flex items-center justify-center">
                  <ArrowUpTrayIcon className="w-3 h-3 text-[#0B4F70]" />
                </div>
                <div className="text-xs">
                  <strong>Upload</strong> from computer
                </div>
              </div>
              <div className="flex items-center border border-neutral-200 rounded-lg w-[12rem] h-[4rem] drop-shadow-sm bg-white px-4 py-2 gap-3">
                <div className="bg-[#FCEBC8] rounded-lg w-6 h-6 flex items-center justify-center">
                  <ArrowDownTrayIcon className="w-3 h-3 text-[##23B29]" />
                </div>
                <div className="text-xs">
                  <strong>Import</strong> from Drive and more
                </div>
              </div>
              <div className="flex items-center border border-neutral-200 rounded-lg w-[12rem] h-[4rem] drop-shadow-sm bg-white px-4 py-2 gap-3">
                <div className="bg-[#CCDAF8] rounded-lg w-6 h-6 flex items-center justify-center">
                  <IoCreateOutline className="w-3 h-3 text-[#3A408B]" />
                </div>
                <div className="text-xs">
                  <strong>Create</strong> new or from template
                </div>
              </div>
              <div className="flex items-center border border-neutral-200 rounded-lg w-[12rem] h-[4rem] drop-shadow-sm bg-white px-4 py-2 gap-3">
                <div className="bg-[#CEF3E5] rounded-lg w-6 h-6 flex items-center justify-center">
                  <TbBoxMultiple className="w-3 h-3 text-[#26817E]" />
                </div>
                <div className="text-xs">
                  <strong>Create</strong> a new space
                </div>
              </div>
            </div>
            <div className="w-full h-[20rem] border border-neutral-200 rounded-xl flex items-center gap-6 pl-10">
              <div className="flex flex-col gap-4 text-sm w-6/12">
                <div className="font-bold text-2xl">
                  Share your latest meeting
                </div>
                <div className="font-medium">
                  Copy the link to share this meeting or click on one of the
                  integrations to quickly post this meeting to your favorite
                  platform.
                </div>
                <div className="flex flex-col gap-2">
                  <div className="flex justify-center gap-1 items-center bg-blue-500 text-white rounded-lg px-8 py-1 w-max">
                    <LinkIcon className="w-3 h-3" />
                    <div>Copy Link</div>
                  </div>
                  <div className="flex items-center gap-2">
                    <div className="rounded-full p-1 w-10 h-10 ">
                      <img
                        src={require("../../assets/slack-logo-thumb.png")}
                        alt="Slack Logo"
                        className="w-full h-full object-contain"
                      />
                    </div>
                    <div className="rounded-full p-1 w-8 h-8 ">
                      <img
                        src={require("../../assets/teams_logo.jpg")}
                        alt="Teams Logo"
                        className="w-full h-full object-contain"
                      />
                    </div>
                    <div className="rounded-full p-1 w-8 h-8 ">
                      <img
                        src={require("../../assets/discord_logo.png")}
                        alt="Discord Logo"
                        className="w-full h-full object-contain"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-full h-full">
                <img
                  src={require("../../assets/meeting-thumbnail.png")}
                  alt="meeting thumbnail"
                  className="object-cover h-full w-full rounded-xl"
                />
              </div>
            </div>
            <div className="flex flex-col gap-4">
              <div className="flex flex-col">
                <div></div>
                <div className="font-xs text-sm font-semibold">
                  Recent Meetings
                </div>
              </div>
              <div className="flex flex-wrap gap-4">
                {Object.values(meetings).length > 0 &&
                  Object.values(meetings).map((meeting) => {
                    const thumbnailUrl = `https://juno-ai.s3.amazonaws.com/${meeting.thumbnail_key}`;
                    return (
                      <Link
                        to={`/meeting/${meeting?.id}`}
                        className="flex flex-col border border-neutral-200 hover:shadow-sm rounded-b-xl w-[16.5rem] rounded-t-xl"
                      >
                        <div className="relative w-full">
                          <img
                            src={
                              meeting.thumbnail_key
                                ? thumbnailUrl
                                : require("../../assets/meeting-thumbnail.png")
                            }
                            alt="meeting thumbnail"
                            className="object-fill h-[8rem] w-full rounded-t-xl"
                          />
                        </div>
                        <div className="flex flex-col text-sm pt-4 px-4 bg-white rounded-b-xl pb-6">
                          <div className="font-semibold">{meeting.title}</div>
                          <div className="text-xs text-neutral-600">
                            Mar 31 2023, 2:24 PM
                          </div>
                        </div>
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
        {newMeetingVisible && (
          <NewMeeting setNewMeetingVisible={setNewMeetingVisible} />
        )}
      </div>
    </div>
  );
}

export default Dashboard;
