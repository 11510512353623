import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const jobsSlice = createSlice({
  name: "jobs",
  initialState: initialState,
  reducers: {
    setJobs: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    deleteJob: (state, action) => {
      delete state.data[action.payload];
    },
  },
});

export const { setJobs, deleteJob } = jobsSlice.actions;
export default jobsSlice.reducer;
