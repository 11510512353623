import { useState } from "react";
import { supabase } from "../../supabase";
import { Navigate, useNavigate } from "react-router-dom";

const Login = ({ setSession }) => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Implement login logic here
    const { data, error } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (error) {
      console.error("Error logging in:", error);
      return;
    }

    if (data?.session) {
      setSession(data.session);
      navigate("/");
    } else {
      console.error("No session found in data:", data);
    }
    console.log("User logged in:", data);

    // let's navigate to the dashboard
    setLoading(false);
  };

  return (
    <div className="w-full h-full flex justify-center items-center">
      {loading ? (
        // <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} />
        "Loading"
      ) : (
        <div className="flex h-full">
          <div className="w-6/12 flex items-center justify-center min-h-screen">
            <div className="flex flex-col gap-8">
              <img
                src={require("../../assets/JUNO.jpg")}
                alt="juno logo"
                className="w-16 h-16 rounded-xl"
              />
              <div className="gap-6 flex flex-col">
                <div className="flex flex-col gap-2">
                  <div className="font-semibold text-2xl">Welcome back 👋</div>
                  <div>Sign in via your email and password to continue</div>
                </div>
                <input
                  type="email"
                  placeholder="Email"
                  className="border border-neutral-200 px-4 py-2 rounded-xl"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <input
                  type="password"
                  placeholder="Password"
                  className="border border-neutral-200 px-4 py-2 rounded-xl"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div
                  className="flex items-center justify-center bg-[#1B4CFF] text-white px-4 py-2 rounded-xl cursor-pointer"
                  onClick={handleLogin}
                >
                  Login
                </div>
                <div className="flex flex-col gap-2 text-sm text-[#0756B5] font-semibold">
                  <div>Forgot Password?</div>
                  <div className="text-black">Or</div>
                  <div
                    className="cursor-pointer"
                    onClick={() => navigate("/signup")}
                  >
                    Create Account
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="border border-neutral-200 w-6/12">
            <img
              src={require("../../assets/splash-login.png")}
              alt="login"
              className=" w-full min-h-screen object-cover"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
