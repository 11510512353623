import React, { useEffect, useState } from "react";
import { FaRegCalendar, FaRegClock } from "react-icons/fa";
import { FaCircleUp } from "react-icons/fa6";
import { IoCopyOutline } from "react-icons/io5";
import { useNavigate, useParams } from "react-router-dom";
import { supabase } from "../../supabase";
import { Dropdown, Spin } from "antd";
import { IoMdAdd } from "react-icons/io";
import axios from "axios";
import Markdown from "react-markdown";
import JunoChat from "../JunoChat/JunoChat";
import Topbar from "../Sidebars/Topbar";
import { useSelector } from "react-redux";
import Tiptap from "../Editor/TipTap";
import { LoadingOutlined } from "@ant-design/icons";
import { ChevronDownIcon, TrashIcon } from "@heroicons/react/24/outline";
import { serverUrl } from "../../utils/isDev";

function Meeting() {
  const navigate = useNavigate();
  const { meetingId } = useParams();
  const meeting = useSelector((state) => state.meetings.data[meetingId] || {});
  const { data: reports = {} } = useSelector((state) => state.reports || {});
  const { data: templates = {} } = useSelector(
    (state) => state.templates || {}
  );

  useEffect(() => {
    if (!meetingId) {
      navigate("/");
    }
  }, [meetingId]);

  // const [meeting, setMeeting] = useState(null);
  const [meetingEditable, setMeetingEditable] = useState(meeting);
  const [reportsEditable, setReportsEditable] = useState({});
  const [reportsArr, setReportsArr] = useState([]);
  const [primaryTemplateId, setPrimaryTemplateId] = useState(null);
  const [summaryReport, setSummaryReport] = useState(null);
  const [defaultDropdown, setDefaultDropdown] = useState([
    {
      key: "100",
      label: <div>Add new template</div>,
    },
  ]);
  const [selectedReport, setSelectedReport] = useState(null);
  const [content, setContent] = useState("");
  const reportOptions = [
    {
      key: "duplicate",
      label: <div>Duplicate</div>,
    },
    {
      key: "delete",
      label: (
        <div
          onClick={async () => {
            if (selectedReport) {
              const { error } = await supabase
                .from("reports")
                .delete()
                .eq("id", selectedReport);

              if (error) {
                console.error("Error deleting report", error);
              }

              setSelectedReport(null);
            }
          }}
        >
          Delete
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (Object.keys(meeting).length !== 0) {
      setMeetingEditable(meeting);
    }
  }, [meeting]);

  useEffect(() => {
    if (selectedReport === null) {
      // we only want reports when the reports.meeting_id is equal to the meetingId
      const filteredReports = Object.values(reports).filter((report) => {
        return (
          Number(report.meeting_id) === Number(meetingId) && report.is_primary
        );
      });

      setReportsEditable(filteredReports[0] || {});
      // setContent(filteredReports[0]?.text);
      setSelectedReport(filteredReports[0]?.id);
    }
  }, [reports, meetingId]);

  useEffect(() => {
    // when the selectedReport changes, we need to update the content
    if (selectedReport) {
      const report = reports[selectedReport];
      setContent(report?.text);
    }
  }, [selectedReport, reports]);

  // we need to populate the dropdown with the templates
  useEffect(() => {
    const dropdownItems = Object.values(templates).map((template) => {
      return {
        key: template.id,
        label: <div>{template.name}</div>,
        onClick: () => handleDropdownClick(template),
      };
    });

    setDefaultDropdown([
      ...dropdownItems,
      {
        key: "100",
        label: <div>Add new template</div>,
      },
    ]);
  }, [templates]);

  const handleDropdownClick = async (template) => {
    // we need to first check if the report with that template already exists
    var report;
    if (Object.keys(reports).length > 0) {
      report = Object.values(reports).find((report) => {
        return (
          Number(report.meeting_id) === Number(meetingId) &&
          Number(report.template_id) === Number(template.id)
        );
      });
    }

    if (report) {
      console.log("report exist");
      // if the report exist then we want to set the selectedReport to that report
      setSelectedReport(report.id);
      return;
    } else {
      try {
        console.log("serverUrl", serverUrl);
        const response = await axios.post(`${serverUrl}/createNewReport`, {
          meetingId: meetingId,
          templateId: template.id,
        });

        const { data } = response;

        if (data) {
          setSelectedReport(data.id);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  useEffect(() => {
    if (Object.keys(reports).length > 0) {
      const arr = Object.values(reports).filter((report) => {
        return Number(report.meeting_id) === Number(meetingId);
      });

      setReportsArr(arr);
    }
  }, [reports, meetingId]);

  return (
    <div className="flex flex-col flex-1 w-full h-screen">
      <Topbar meetingTitle={meeting?.title} />
      <div className="flex w-full h-screen overflow-y-auto">
        <div className=" w-7/12 flex flex-col p-4 border border-neutral-200 border-y-0 border-l-0 lg:p-6">
          <div className="flex flex-col gap-4 w-full">
            <input
              type="text"
              value={meetingEditable?.title}
              onChange={(e) =>
                setMeetingEditable({
                  ...meetingEditable,
                  title: e.target.value,
                })
              }
              className="text-4xl font-light outline-none"
            />
            <div className="flex gap-2 items-center text-sm">
              <div className="flex gap-2 items-center">
                <div className="">Morty Smith</div>
              </div>
              <div className="flex gap-2 items-center">
                <FaRegCalendar />
                <div className="">Apr 24 at 1:06 PM - 1:09 PM</div>
              </div>
              <div className="flex gap-2 items-center">
                <FaRegClock />
                <div className="">01:32</div>
              </div>
              <div className="h-full p-[0.5px] bg-neutral-200"></div>
              <div className="flex gap-2 items-center">
                <IoCopyOutline />
                <div className="">Copy Summary</div>
              </div>
              <div className="h-full p-[0.5px] bg-neutral-200"></div>
              <div
                className="flex gap-2 items-center cursor-pointer"
                onClick={async () => {
                  const { error } = await supabase
                    .from("meetings")
                    .delete()
                    .eq("id", meetingId);

                  if (error) {
                    console.error("Error deleting meeting", error);
                  }

                  navigate("/");
                }}
              >
                <TrashIcon className="w-4 h-4" />
                <div className="">Delete</div>
              </div>
            </div>
          </div>

          <div className="mt-6 mb-6 w-full">
            <div className="border-[0.5px] border-x-0 border-t-0 border-neutral-200 flex gap-2 overflow-x-auto">
              <div className="overflow-x-auto flex gap-2">
                {reportsArr.length > 0 &&
                  reportsArr.map((report, index) => {
                    return (
                      <div
                        key={report.id}
                        className={`group flex items-center gap-2 py-2 px-1 cursor-pointer ${
                          selectedReport === report.id &&
                          "border-2 border-blue-600 border-t-0 border-x-0"
                        } ${report?.loading && "text-gray-400"}`}
                        onClick={() => {
                          if (!report.loading) {
                            setSelectedReport(report.id);
                          }
                        }}
                      >
                        <div className="whitespace-nowrap">
                          {templates[report.template_id]?.name}
                        </div>
                        {report?.loading && (
                          <Spin
                            indicator={<LoadingOutlined spin />}
                            size="small"
                          />
                        )}
                        {!report?.loading && selectedReport === report.id && (
                          <Dropdown
                            menu={{ items: reportOptions }}
                            trigger={["click"]}
                          >
                            <div className="">
                              <ChevronDownIcon className="h-4 w-4" />
                            </div>
                          </Dropdown>
                        )}
                      </div>
                    );
                  })}
              </div>
              <Dropdown menu={{ items: defaultDropdown }} trigger={["click"]}>
                <div className="flex gap-2 items-center cursor-pointer">
                  <IoMdAdd />
                  {/* <div className="">Add</div> */}
                </div>
              </Dropdown>
            </div>
          </div>

          <div className="flex flex-col gap-4 w-full h-full overflow-y-auto">
            <Tiptap content={content} reportId={selectedReport} />
          </div>
        </div>
        <div className="flex flex-col w-5/12 mb-4 overflow-y-auto">
          <JunoChat meetingId={meetingId} />
        </div>
      </div>
    </div>
  );
}

export default Meeting;
