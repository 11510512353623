import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: {},
  orgs: {},
  activeOrgId: null,
};

const appSlice = createSlice({
  name: "app",
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setOrgs: (state, action) => {
      state.orgs = {
        ...state.orgs,
        ...action.payload,
      };
    },
    setActiveOrgId: (state, action) => {
      state.activeOrgId = action.payload;
    },
  },
});

export const { setUser, setOrgs, setActiveOrgId } = appSlice.actions;
export default appSlice.reducer;
