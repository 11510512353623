import { useEffect, useState } from "react";
import { supabase } from "../../supabase";
import { Navigate } from "react-router-dom";

const AuthWrapper = ({ children }) => {
  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      setLoading(false);
    });

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, session) => {
        setSession(session);
      }
    );

    return () => {
      authListener.subscription.unsubscribe();
    };
  }, []);

  if (loading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        {/* <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} />} /> */}
        Loading
      </div>
    );
  }

  if (!session) {
    return <Navigate to="/login" replace />;
  }

  return children;
};

export default AuthWrapper;
