import React, { useEffect, useState } from "react";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

function JobStatusToast({ jobs, meetings }) {
  const [isMeeting, setIsMeeting] = useState(false);
  let latestJob = null;
  let latestMeeting = null;

  if (jobs) {
    latestJob = Object.values(jobs)
      .filter((job) => job.status !== "completed" && job.status !== "failed")
      .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))[0];
  }

  if (meetings) {
    latestMeeting = Object.values(meetings).find(
      (meeting) => meeting.id === latestJob?.meeting_id
    );
  }
  return (
    <>
      {latestJob && (
        <div className="fixed bottom-6 right-8 shadow-sm rounded-full px-4 py-2 z-10 min-w-[20rem] drop-shadow-2xl bg-neutral-800 text-white gap-2 text-xs flex flex-col">
          <div className="flex items-center justify-between gap-4">
            <div className="flex gap-4 items-center ">
              <Spin indicator={<LoadingOutlined spin />} />
              <div className="font-bold text-lg">
                {latestJob?.stage !== 0 && latestJob?.stage}0%
              </div>
              <div className="text-white/70 truncate w-40">
                {latestJob?.message}
              </div>
            </div>
            <div className=" py-2 p-1 cursor-pointer">Cancel</div>
          </div>
        </div>
      )}
    </>
  );
}

export default JobStatusToast;
