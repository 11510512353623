// src/Tiptap.tsx
import {
  useEditor,
  EditorContent,
  FloatingMenu,
  BubbleMenu,
} from "@tiptap/react";
import StarterKit from "@tiptap/starter-kit";
import { useEffect, useState } from "react";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Document from "@tiptap/extension-document";
import Typography from "@tiptap/extension-typography";
import { marked } from "marked";
import { supabase } from "../../supabase";

// define your extension array
const extensions = [StarterKit, Document, Paragraph, Text, Typography];

const Tiptap = ({ content = "", reportId }) => {
  const [htmlContent, setHtmlContent] = useState("");
  const [contentEditable, setContentEditable] = useState(content);

  const saveContent = async () => {
    // save content to the database
    const html = editor.getHTML();
    const { data, error } = await supabase
      .from("reports")
      .update({ text: html })
      .eq("id", reportId);

    if (error) {
      console.error("Error updating report", error);
    }
  };

  useEffect(() => {
    setContentEditable(content);
    editor.commands.setContent(content);
  }, [content]);

  const editor = useEditor({
    extensions,
    content: content || "<p>Hello World! 🌍️</p>",
    onUpdate: ({ editor }) => {
      setContentEditable(editor.getHTML());
    },
    onBlur: ({ editor, event }) => {
      console.log("Editor lost focus");
      saveContent();
    },
    editorProps: {
      attributes: {
        class:
          "prose prose-sm sm:prose-base lg:prose-lg xl:prose-2xl focus:outline-none",
      },
    },
  });

  if (!editor) {
    return null;
  }

  return (
    <>
      {editor && (
        <BubbleMenu editor={editor} tippyOptions={{ duration: 100 }}>
          <div className="bg-white border border-gray-200 rounded-lg shadow-md flex p-0.5">
            <button
              onClick={() => editor.chain().focus().toggleBold().run()}
              className={`px-2 py-1 rounded focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                editor.isActive("bold")
                  ? "bg-purple-500 text-white hover:bg-purple-600"
                  : "hover:bg-gray-100"
              }`}
            >
              Bold
            </button>
            <button
              onClick={() => editor.chain().focus().toggleItalic().run()}
              className={`px-2 py-1 rounded focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                editor.isActive("italic")
                  ? "bg-purple-500 text-white hover:bg-purple-600"
                  : "hover:bg-gray-100"
              }`}
            >
              Italic
            </button>
            <button
              onClick={() => editor.chain().focus().toggleStrike().run()}
              className={`px-2 py-1 rounded focus:outline-none focus:ring-2 focus:ring-purple-500 ${
                editor.isActive("strike")
                  ? "bg-purple-500 text-white hover:bg-purple-600"
                  : "hover:bg-gray-100"
              }`}
            >
              Strike
            </button>
          </div>
        </BubbleMenu>
      )}
      <EditorContent editor={editor} />
    </>
  );
};

export default Tiptap;
