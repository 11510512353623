import { Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { supabase } from "../../supabase";
import { useSelector } from "react-redux";

function Topbar({ meetingTitle, setNewMeetingVisible }) {
  const {
    user = {},
    orgs = {},
    activeOrgId = null,
  } = useSelector((state) => state.app || {});
  const [pageTitle, setPageTitle] = useState("");
  const pathName = window.location.pathname;

  useEffect(() => {
    if (pathName === "/") {
      // setPageTitle("Dashboard");
    } else if (pathName.split("/")[1] === "meeting") {
      setPageTitle(`Meeting/${meetingTitle}`);
    } else if (pathName === "/templates") {
      setPageTitle("Templates");
    } else if (pathName === "/settings") {
      setPageTitle("Settings");
    }
  }, [pathName, meetingTitle]);

  const menu = [
    {
      key: "1",
      label: <div>Profile</div>,
    },
    {
      key: "2",
      label: <div>Settings</div>,
    },
    {
      key: "3",
      label: (
        <div
          onClick={async () => {
            const { error } = await supabase.auth.signOut();

            if (error) {
              console.error("Error logging out:", error);
              return;
            }
          }}
        >
          Logout
        </div>
      ),
    },
  ];

  const getUserInitials = (name) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("");
  };

  return (
    <div className="w-full items-center px-4 py-2 border border-x-0 border-t-0 border-neutral-200 flex justify-between h-[3.56rem]">
      <div className="text-sm">{pageTitle}</div>
      <div className="flex  items-center gap-2">
        <div className="flex items-center relative">
          <IoIosSearch className="text-neutral-400 absolute top-2 left-2" />
          <input
            className="px-4 py-2 text-xs outline-none border-none w-[15rem] pl-8 rounded-full"
            placeholder="Search meetings and spaces"
          ></input>
        </div>
        <div
          className="px-4 py-2 bg-blue-500 text-white rounded-full text-xs cursor-pointer"
          onClick={() => setNewMeetingVisible(true)}
        >
          New Meeting
        </div>
        <Dropdown menu={{ items: menu }} trigger={["click"]}>
          <div className="w-8 h-8 border-pink-500 border-[1px] font-medium text-pink-500 rounded-full p-1 flex items-center justify-center text-xs cursor-pointer">
            {(user?.name && getUserInitials(user.name)) || "ERR"}
          </div>
        </Dropdown>
      </div>
    </div>
  );
}

export default Topbar;
