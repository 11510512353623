import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const reportsSlice = createSlice({
  name: "reports",
  initialState: initialState,
  reducers: {
    setReports: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    deleteReport: (state, action) => {
      delete state.data[action.payload];
    },
  },
});

export const { setReports, deleteReport } = reportsSlice.actions;
export default reportsSlice.reducer;
