import "./App.css";
import LeftSidebar from "./components/Sidebars/LeftSidebar";
import Dashboard from "./components/Dashboard/Dashboard";
import { useEffect, useState } from "react";
import { supabase } from "./supabase";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  Routes,
  useLocation,
} from "react-router-dom";
import Meeting from "./components/Meeting/Meeting";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { deleteJob, setJobs } from "./redux/jobsSlice";
import { deleteMeeting, setMeetings } from "./redux/meetingsSlice";
import { setTemplates, deleteTemplate } from "./redux/templatesSlice";
import {
  deleteReport,
  setReports,
  setReportsByMeetingId,
} from "./redux/reportsSlice";
import JobStatusToast from "./components/General/JobStatusToast";
import Templates from "./components/Templates/Templates";
import AuthWrapper from "./components/Auth/AuthWrapper";
import Login from "./components/Auth/Login";
import Signup from "./components/Auth/Signup";
import { setActiveOrgId, setOrgs, setUser } from "./redux/appSlice";

function App() {
  const { data: jobs = {} } = useSelector((state) => state.jobs) || {};
  const { data: meetings = {} } = useSelector((state) => state.meetings) || {};
  const { data: templates = {} } =
    useSelector((state) => state.templates) || {};
  const { data: reports = {} } = useSelector((state) => state.reports) || {};
  const {
    user = {},
    orgs = {},
    activeOrgId = null,
  } = useSelector((state) => state.app || {});
  const [session, setSession] = useState(null);

  const dispatch = useDispatch();

  // we need to fetch the user based on the session
  useEffect(() => {
    if (session?.user?.id) {
      const fetchUser = async () => {
        try {
          const { data, error } = await supabase
            .from("users")
            .select()
            .eq("id", session.user.id)
            .single();

          if (error) {
            console.log("error fetching user", error);
            throw error;
          }

          dispatch(setUser(data));
        } catch (error) {
          console.log("Error user fetch", error);
        }
      };

      fetchUser();
    }
  }, [session, dispatch]);

  // we need to fetch all the orgs that the user is a part of
  useEffect(() => {
    if (session?.user?.id) {
      const fetchOrgs = async () => {
        try {
          const { data, error } = await supabase
            .from("orgs_users")
            .select()
            .eq("user_id", session.user.id);

          if (error) {
            console.log("error", error);
            throw error;
          }

          // we need to fetch the orgs based on the org_ids
          const orgIds = data.map((org) => org.org_id);

          const { data: orgData, error: orgError } = await supabase
            .from("orgs")
            .select()
            .in("id", orgIds);

          if (orgError) {
            throw orgError;
          }

          if (orgData.length > 0) {
            orgData.forEach((org) => {
              dispatch(setOrgs({ [org.id]: org }));
            });

            // we need to set the first org as the default org
            dispatch(setActiveOrgId(orgData[0].id));
          }
        } catch (error) {
          console.log("Error", error);
        }
      };

      fetchOrgs();
    }
  }, [session, dispatch]);
  // session listener
  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
    });

    supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
    });
  }, []);

  // initial job fetch
  useEffect(() => {
    // Function to fetch initial jobs
    if (user?.id && activeOrgId) {
      const fetchInitialJobs = async () => {
        try {
          const { data, error } = await supabase
            .from("job_status")
            .select()
            .eq("org_id", activeOrgId)
            .eq("user_id", user?.id);

          if (error) {
            console.log("error", error);
            throw error;
          }

          // Convert array to object with id as key
          const jobsObject = data.reduce((acc, job) => {
            acc[job.id] = job;
            return acc;
          }, {});

          dispatch(setJobs(jobsObject));
        } catch (error) {
          console.error("Error fetching initial jobs:", error);
          // Handle error (e.g., set an error state)
        }
      };

      fetchInitialJobs();
    }
  }, [dispatch, user, activeOrgId]);
  // job listener for new jobs
  useEffect(() => {
    try {
      if (activeOrgId && user.id) {
        const handleJobChange = (payload) => {
          dispatch(
            setJobs({
              [payload.new.id]: payload.new,
            })
          );
        };

        const subscribeToJobStatus = () => {
          return supabase
            .channel("job_insert")
            .on(
              "postgres_changes",
              {
                event: "INSERT",
                schema: "public",
                table: "job_status",
                filter: {
                  org_id: activeOrgId,
                  user_id: user.id,
                },
              },
              handleJobChange
            )
            .subscribe();
        };

        const subscription = subscribeToJobStatus();

        return () => {
          subscription.unsubscribe();
        };
      }
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch, activeOrgId, user]);
  // job listener for job updates
  useEffect(() => {
    try {
      if ((user?.id, activeOrgId)) {
        const handleJobChange = (payload) => {
          dispatch(
            setJobs({
              [payload.new.id]: payload.new,
            })
          );
        };

        const subscribeToJobStatus = () => {
          return supabase
            .channel("job_update")
            .on(
              "postgres_changes",
              {
                event: "UPDATE",
                schema: "public",
                table: "job_status",
                filter: {
                  org_id: activeOrgId,
                  user_id: user.id,
                },
              },
              handleJobChange
            )
            .subscribe();
        };

        const subscription = subscribeToJobStatus();

        return () => {
          subscription.unsubscribe();
        };
      }
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch, activeOrgId, user]);
  // job listener for job deletes
  useEffect(() => {
    try {
      if ((user?.id, activeOrgId)) {
        const handleJobChange = (payload) => {
          dispatch(deleteJob(payload.old.id));
        };
        // let's handle delete jobs that come through the listener
        const subscribeToJobStatus = () => {
          return supabase
            .channel("job_delete")
            .on(
              "postgres_changes",
              {
                event: "DELETE",
                schema: "public",
                table: "job_status",
                filter: {
                  org_id: activeOrgId,
                  user_id: user.id,
                },
              },
              handleJobChange
            )
            .subscribe();
        };

        const subscription = subscribeToJobStatus();

        return () => {
          subscription.unsubscribe();
        };
      }
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch, activeOrgId, user]);
  //initial meeting fetch
  useEffect(() => {
    try {
      const initialMeetingFetch = async () => {
        try {
          const { data, error } = await supabase.from("meetings").select("*");

          if (error) {
            throw error;
          }

          const meetingObject = data.reduce((acc, meeting) => {
            acc[meeting.id] = meeting;
            return acc;
          }, {});

          dispatch(setMeetings(meetingObject));
        } catch (error) {
          console.log("Error", error);
        }
      };

      initialMeetingFetch();
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch]);
  // meeting listener for new meetings
  useEffect(() => {
    try {
      const handleMeetingChange = (payload) => {
        dispatch(
          setMeetings({
            [payload.new.id]: payload.new,
          })
        );
      };

      const subscribeToMeeting = () => {
        return supabase
          .channel("meeting_insert")
          .on(
            "postgres_changes",
            { event: "INSERT", schema: "public", table: "meetings" },
            handleMeetingChange
          )
          .subscribe();
      };

      const subscription = subscribeToMeeting();

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch]);
  // meeting listener for meeting updates
  useEffect(() => {
    try {
      const handleMeetingChange = (payload) => {
        dispatch(
          setMeetings({
            [payload.new.id]: payload.new,
          })
        );
      };

      const subscribeToMeeting = () => {
        return supabase
          .channel("meeting_update")
          .on(
            "postgres_changes",
            { event: "UPDATE", schema: "public", table: "meetings" },
            handleMeetingChange
          )
          .subscribe();
      };

      const subscription = subscribeToMeeting();

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch]);
  // meeting listener for meeting deletes
  useEffect(() => {
    try {
      const handleMeetingChange = (payload) => {
        dispatch(deleteMeeting(payload.old.id));
      };

      const subscribeToMeeting = () => {
        return supabase
          .channel("meeting_delete")
          .on(
            "postgres_changes",
            { event: "DELETE", schema: "public", table: "meetings" },
            handleMeetingChange
          )
          .subscribe();
      };

      const subscription = subscribeToMeeting();

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch]);
  // initial templates fetch
  useEffect(() => {
    try {
      const fetchInitialTemplates = async () => {
        try {
          const { data, error } = await supabase
            .from("templates")
            .select()
            .eq("org_id", "1")
            .select("*");

          if (error) {
            throw error;
          }

          const templateObject = data.reduce((acc, template) => {
            acc[template.id] = template;
            return acc;
          }, {});

          dispatch(setTemplates(templateObject));
        } catch (error) {
          console.log("Error", error);
        }
      };

      fetchInitialTemplates();
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch]);
  // template listener for new templates
  useEffect(() => {
    try {
      const handleTemplateChange = (payload) => {
        dispatch(
          setTemplates({
            [payload.new.id]: payload.new,
          })
        );
      };

      const subscribeToTemplate = () => {
        return supabase
          .channel("template_insert")
          .on(
            "postgres_changes",
            { event: "INSERT", schema: "public", table: "templates" },
            handleTemplateChange
          )
          .subscribe();
      };

      const subscription = subscribeToTemplate();

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch]);
  // template listener for template updates
  useEffect(() => {
    try {
      const handleTemplateChange = (payload) => {
        dispatch(
          setTemplates({
            [payload.new.id]: payload.new,
          })
        );
      };

      const subscribeToTemplate = () => {
        return supabase
          .channel("template_update")
          .on(
            "postgres_changes",
            { event: "UPDATE", schema: "public", table: "templates" },
            handleTemplateChange
          )
          .subscribe();
      };

      const subscription = subscribeToTemplate();

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch]);
  // template listener for template deletes
  useEffect(() => {
    try {
      const handleTemplateChange = (payload) => {
        dispatch(deleteTemplate(payload.old.id));
      };

      const subscribeToTemplate = () => {
        return supabase
          .channel("template_delete")
          .on(
            "postgres_changes",
            { event: "DELETE", schema: "public", table: "templates" },
            handleTemplateChange
          )
          .subscribe();
      };

      const subscription = subscribeToTemplate();

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch]);
  // initial reports fetch
  useEffect(() => {
    try {
      const fetchInitialReports = async () => {
        try {
          const { data, error } = await supabase
            .from("reports")
            .select()
            .eq("org_id", "1")
            .select("*");

          if (error) {
            throw error;
          }

          const reportObject = data.reduce((acc, report) => {
            acc[report.id] = report;
            return acc;
          }, {});

          dispatch(setReports(reportObject));

          // // let's also set the reports to the meeting
          // data.forEach((report) => {
          //   // we want to set reports based on it's meeting_id
          //   dispatch(setReportsByMeetingId());
          // });
        } catch (error) {
          console.log("Error", error);
        }
      };

      fetchInitialReports();
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch]);
  // report listener for new reports
  useEffect(() => {
    try {
      const handleReportChange = (payload) => {
        console.log("new report", payload.new);
        dispatch(
          setReports({
            [payload.new.id]: payload.new,
          })
        );
      };

      const subscribeToReport = () => {
        return supabase
          .channel("report_insert")
          .on(
            "postgres_changes",
            { event: "INSERT", schema: "public", table: "reports" },
            handleReportChange
          )
          .subscribe();
      };

      const subscription = subscribeToReport();

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch]);
  // report listener for report updates
  useEffect(() => {
    try {
      const handleReportChange = (payload) => {
        dispatch(
          setReports({
            [payload.new.id]: payload.new,
          })
        );
      };

      const subscribeToReport = () => {
        return supabase
          .channel("report_update")
          .on(
            "postgres_changes",
            { event: "UPDATE", schema: "public", table: "reports" },
            handleReportChange
          )
          .subscribe();
      };

      const subscription = subscribeToReport();

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch]);
  // report listener for report deletes
  useEffect(() => {
    try {
      const handleReportChange = (payload) => {
        dispatch(deleteReport(payload.old.id));
      };

      const subscribeToReport = () => {
        return supabase
          .channel("report_delete")
          .on(
            "postgres_changes",
            { event: "DELETE", schema: "public", table: "reports" },
            handleReportChange
          )
          .subscribe();
      };

      const subscription = subscribeToReport();

      return () => {
        subscription.unsubscribe();
      };
    } catch (error) {
      console.log("Error", error);
    }
  }, [dispatch]);

  return (
    <div className="w-full flex min-h-screen h-full">
      <Routes>
        <Route path="/login" element={<Login setSession={setSession} />} />
        <Route path="/signup" element={<Signup />} />
        <Route
          path="/"
          element={
            <AuthWrapper>
              <Root jobs={jobs} meetings={meetings} />
            </AuthWrapper>
          }
        >
          <Route index element={<Dashboard />} />
          <Route
            path="meeting/:meetingId"
            element={<Meeting templates={templates} />}
          />
          <Route path="templates" element={<Templates />} />
        </Route>
        <Route path="*" element={<div>Page not found</div>} />
      </Routes>
    </div>
  );
}

export default App;

const Root = ({ jobs, meetings }) => {
  const location = useLocation();
  const isMeetingPage = location.pathname.includes("meeting");

  return (
    <div className="w-full flex min-h-screen">
      <div className="h-full">
        <LeftSidebar />
      </div>
      <div className="h-full w-full">
        <Outlet />
        {!isMeetingPage && <JobStatusToast jobs={jobs} meetings={meetings} />}
      </div>
    </div>
  );
};
