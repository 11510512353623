import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  data: {},
};

const templatesSlice = createSlice({
  name: "templates",
  initialState: initialState,
  reducers: {
    setTemplates: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    deleteTemplate: (state, action) => {
      delete state.data[action.payload];
    },
  },
});

export const { setTemplates, deleteTemplate } = templatesSlice.actions;
export default templatesSlice.reducer;
