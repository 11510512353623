import { streamText } from "ai";
import React, { useEffect, useRef, useState } from "react";
import { FaCircleUp } from "react-icons/fa6";
import openai from "../../utils/openai";
import axios from "axios";
import Markdown from "react-markdown";
import { GoCopy } from "react-icons/go";
import { LuCopy, LuRefreshCcw } from "react-icons/lu";
import { ChevronDownIcon, SparklesIcon } from "@heroicons/react/24/outline";
import { FiThumbsDown } from "react-icons/fi";
import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { serverUrl } from "../../utils/isDev";

function JunoChat({ meetingId }) {
  const [chat, setChat] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [streamedMessage, setStreamedMessage] = useState("");
  const [junoTyping, setJunoTyping] = useState(false);
  const chatEndRef = useRef(null);

  const handleChat = async () => {
    try {
      setJunoTyping(true);
      // before we set the new streamed message let's save the prev one to the chatlog
      if (streamedMessage !== "") {
        setChat((prev) => [
          ...prev,
          { role: "Juno", content: streamedMessage, time: new Date() },
        ]);

        setStreamedMessage("");
      }
      // let's update the chatlog with the user's message
      setChat((prev) => [
        ...prev,
        { role: "user", content: textInput, time: new Date() },
      ]);

      setTextInput("");

      const response = await axios.post(`${serverUrl}/chat`, {
        meetingId: meetingId,
        message: textInput,
      });

      const prompt = response.data.prompt;

      const { textStream } = await streamText({
        model: openai("gpt-4o"),
        messages: [
          {
            role: "system",
            content:
              "You are a helpful chat bot assistant. Answer the users question based on the transcript data.",
          },
          {
            role: "user",
            content: prompt,
          },
        ],
        onFinish: (result) => {
          // lets add the final response to the chatlog
          console.log("result", result);
          setChat((prev) => [...prev, { role: "Juno", content: result.text }]);

          setJunoTyping(false);

          // // lets clean up the streamed message
          setStreamedMessage("");
        },
      });

      for await (const textPart of textStream) {
        console.log(textPart);
        setStreamedMessage((prev) => prev + textPart);
      }

      // setStreamedMessage(prompt);
      setJunoTyping(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    // Scroll to the bottom of the chat container whenever chat or streamedMessage changes
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [chat, streamedMessage]);

  return (
    <div className="relative py-4 w-full h-full flex flex-col justify-end overflow-y-auto">
      <div className="flex flex-col px-4 py-4 gap-6 overflow-y-auto flex-1">
        {chat.map((message, index) => (
          <div className="flex gap-2 items-start">
            <div
              className={`min-w-4 min-h-4 mt-[2px] text-white  ${
                message.role === "user" ? "bg-orange-500" : "bg-blue-500"
              } rounded-full`}
            ></div>
            <div key={index} className={`flex flex-col gap-2 items-start`}>
              <div className="font-bold text-sm">
                {message.role === "user" ? "You" : "Juno"}
              </div>
              <div className={`rounded-lg`}>
                <Markdown>{message.content}</Markdown>
              </div>
            </div>
          </div>
        ))}
        <>
          {streamedMessage && (
            <div className="flex gap-2 items-start">
              <div
                className={`min-w-4 min-h-4 mt-[2px] bg-blue-500 rounded-full`}
              ></div>
              <div className="flex flex-col gap-2">
                <div className="font-bold text-sm">Juno</div>
                <div className=" rounded-xl">{streamedMessage}</div>
                <div className="flex">
                  <Tooltip title="Copy" placement="bottom">
                    <div className="p-2 hover:bg-neutral-50 rounded-md cursor-pointer">
                      <LuCopy className="w-4 h-4 text-neutral-500" />
                    </div>
                  </Tooltip>
                  <Tooltip title="Regenerate" placement="bottom">
                    <div className="p-2 hover:bg-neutral-50 rounded-md cursor-pointer">
                      <LuRefreshCcw className="w-4 h-4 text-neutral-500" />
                    </div>
                  </Tooltip>
                  <Tooltip title="Bad Response" placement="bottom">
                    <div className="p-2 hover:bg-neutral-50 rounded-md cursor-pointer">
                      <FiThumbsDown className="w-4 h-4 text-neutral-500" />
                    </div>
                  </Tooltip>
                  <Tooltip title="Change Modal" placement="bottom">
                    <div className="flex items-center p-2 hover:bg-neutral-50 rounded-md cursor-pointer group ">
                      <SparklesIcon className="w-4 h-4 text-neutral-500" />
                      <div className="hidden group-hover:flex text-neutral-500 text-xs duration-300 ease-in-out ">
                        4o
                      </div>
                      <ChevronDownIcon className="w-2 h-2 text-neutral-500 group-hover:text-neutral-600" />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
          )}
          <div ref={chatEndRef} />
        </>
      </div>
      {junoTyping && (
        <div className="text-xs text-neutral-600 px-4 py-2">
          Juno is typing...
        </div>
      )}
      <div className=" w-full px-4 relative">
        <textarea
          className="w-full px-4 py-2 h-10 border border-neutral-200 rounded-lg bg-[#FBFBFB] outline-none"
          placeholder="Message Juno"
          value={textInput}
          onChange={(e) => setTextInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey && textInput.length > 0) {
              e.preventDefault();
              handleChat();
            }
            if (e.key === "Enter" && e.shiftKey) {
              e.preventDefault();
              setTextInput((prev) => prev + "\n");
            }
          }}
          style={{ resize: "none" }}
        ></textarea>
        {junoTyping}
        <FaCircleUp
          className={`absolute right-6 top-2 w-6 h-6 ${
            textInput.length > 0 ? "text-black" : "text-neutral-400"
          } cursor-pointer`}
          onClick={handleChat}
        />
      </div>
    </div>
  );
}

export default JunoChat;
