import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { supabase } from "../supabase";
import { v4 as uuidv4 } from "uuid";
import { isDev, serverUrl } from "../utils/isDev";

const initialState = {
  data: {},
};

export const createMeeting = createAsyncThunk(
  "meetings/createMeeting",
  async (
    {
      meetingTitle,
      selectedFile,
      defaultTemplate,
      newTemplateName,
      newTemplatePrompt,
      providedTranscript,
      providedKeywords,
    },
    { getState }
  ) => {
    try {
      const state = getState();
      const { data: templates } = state.templates;
      const { user, activeOrgId } = state.app;

      const jobId = uuidv4();
      // let's create a new job status
      try {
        await supabase.from("job_status").upsert(
          {
            id: jobId,
            meeting_id: null,
            org_id: activeOrgId,
            user_id: user.id,
            status: "processing",
            message: "Initializing job",
            updated_at: new Date(),
            stage: 0,
          },
          { onConflict: ["id"] }
        );

        console.log("job status created");
      } catch (error) {
        console.error("error creating job status", error);
        throw error;
      }
      // let's generate the presigned url
      const response = await axios.get(`${serverUrl}/generatePresignedUrl`);
      const { fileName, key, url } = response.data || {};

      const base64Data = selectedFile.split(",")[1];
      if (!base64Data) {
        throw new Error("Invalid base64 string");
      }

      // Decode base64 string to binary data
      let binaryData;
      try {
        binaryData = atob(base64Data);
      } catch (error) {
        throw new Error("Failed to decode base64 string");
      }
      const arrayBuffer = new ArrayBuffer(binaryData.length);
      const uint8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < binaryData.length; i++) {
        uint8Array[i] = binaryData.charCodeAt(i);
      }

      // Now let's upload the file to S3
      await axios.put(url, uint8Array, {
        headers: {
          "Content-Type": "video/mp4",
        },
      });

      // let's pass in the templateId to the backend

      let template;

      if (defaultTemplate === "New") {
        template = {
          name: newTemplateName,
          prompt: newTemplatePrompt,
          org_id: "1",
          is_primary_summary: false,
        };
      } else {
        template = templates[defaultTemplate];
      }

      var keywordArr = [];
      if (providedKeywords && providedKeywords.length > 0) {
        keywordArr = providedKeywords.split(",");
      }

      // now let's create a new meeting
      const createMeetingResponse = await axios.post(
        `${serverUrl}/createNewMeeting`,
        {
          meetingTitle: meetingTitle,
          videoKey: key,
          template: template,
          newTemplate: defaultTemplate === "New",
          jobId,
          org_id: activeOrgId,
          user_id: user.id,
          providedTranscript:
            providedTranscript && providedTranscript.length > 0
              ? providedTranscript
              : null,
          providedKeywords:
            keywordArr && keywordArr.length > 0 ? keywordArr : null,
        }
      );

      return;
    } catch (error) {
      console.error("error creating meeting", error);
      return;
    }
  }
);

const meetingsSlice = createSlice({
  name: "meetings",
  initialState: initialState,
  reducers: {
    setMeetings: (state, action) => {
      state.data = {
        ...state.data,
        ...action.payload,
      };
    },
    deleteMeeting: (state, action) => {
      delete state.data[action.payload];
    },
  },
});

export const { setMeetings, deleteMeeting } = meetingsSlice.actions;
export default meetingsSlice.reducer;
